.app {
    margin-left: -8px;
    width: 120px;
}

.privacy-title {
    font-size: 60px;
    font-weight: 600;
    font-style: italic;
    margin-top: 35px;
}

.privacy-contents {
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, "Inter", sans-serif;
}

.privacy-contents h2 {
    font-size: 2rem;
}

.privacy-contents p {
    font-size: 1rem;
}

.privacy-contents a {
    color: #5e4c8c;
}