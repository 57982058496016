
.title {
    font-size: 38pt;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -2px;
}

/* .title-md {
    font-size: 24pt;
    font-weight: 500;
    line-height: 20pt;
    letter-spacing: -2px;
} */