.topBanner {
    font-family: -apple-system, BlinkMacSystemFont, "Inter", sans-serif;
}

.topImage {
    object-fit: contain;
    height: 50%;
    padding-left: 30px;
    padding-right: 30px;
}

.title {
    font-size: 21pt;
    font-weight: 600;
    line-height: 1.3em;
}

.dateLabel {
    text-align: right;
    font-weight: 500;
    color: slategray;
    font-size: 12pt;
}

.contents {
    font-family: "IBM Plex Serif", "Times New Roman", Times, serif;
    font-size: 17px;
    font-weight: 400;
    text-align: left;
}

@media (min-width: 768px) {
    .title {
        font-size: 35pt;
        font-weight: 600;
        line-height: auto;
    }
    .dateLabel {
        font-size: 15pt;
    }
    .topImage {
        height: 50vh;
    }

    .contents {
        font-size: 20px;
    }
}

.contents h1 {
    font-size: 30pt;
}

.contents > p {
    padding: 0.5em;
}

.contents a:hover {
    color: #0056b3;
    text-decoration: underline;
    opacity: 0.7;   
}

.contents a {
    color: #5e4c8c;
    transition: 0.3s ease-out;
}

.contents p {
    /* padding: 0.5em; */
    margin-bottom: 1rem;
}

.contents ul {
    width: 95%;
    font-weight: 300;
    font-size: 18.5px;
    list-style-type: disc;
    padding-inline-start: 40px;
    margin-top: 0;
}

.contents h1, h2, h3, h4, h5, h6 {
    font-family: -apple-system, BlinkMacSystemFont, "Inter", sans-serif;
    font-weight: 600;
    margin-bottom: 0.5rem;
    line-height: 1.2;
}

.contents h1, h2 {
    padding-top: 10px;
}

.contents h3 {
    padding-top: 20pt;
}

.contents h4, h5, h6 {
    padding-top: 8px;
}

.contents h1 {
    font-size: 2em;
}

.contents h2 {
    font-size: 1.6em;
}

.contents h3 {
    font-size: 1.35em;
}

.contents h4 {
    font-size: 1em;
}

.contents h5 {
    font-size: 0.8em;
}

.contents h6 {
    font-size: 0.65em;
}