.vertical {
    width: 36%;
    height: 40%;
}

.horizontal {
    width: 100%;
    height: 5%;
}

.image {
    display: inline;
    max-width: 100%;
    padding-bottom: 1.5rem;
    overflow-x: scroll;
    border-style: none;
}

.subtitle {
    font-family: -apple-system, BlinkMacSystemFont, "Inter", sans-serif;
    color: gray;
}

@media (min-width: 768px) {
    .floatLeft {
        float: left;
    }
    
    .floatRight {
        float: right;
    }

    .floatLeft, .floatRight {
        padding: 8px;
    }
}