@media (prefers-reduced-motion: no-preference) {
    #nav-content, nav {
        transition: 0.4s;
    }
}

/* #nav-content {
    
} */

/* nav.nav-scroll-top {
    padding-top: 0;
}

#nav-content.nav-scroll-top {
    margin: 0;
    border-radius: 0;
} */

nav.nav-scroll-down {
    margin-top: 0px;
    padding-top: 10px;
}

#nav-content.nav-scroll-down {
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 12px;
    /* color: white;*/
    /* background-color: rgba(240, 240, 240, 0.5); */
    background-color: rgba(230, 239, 241, 0.3);
    /*background-color: rgba(12, 7, 30, 0.4); */
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.3);
}

.small-width-bar {
    font-size: 12.5pt;
    transition: none;
}

@media (prefers-reduced-motion: reduce) {
    .small-width-bar {
        transition: none !important;
    }
}

@media (max-width: 768px) {
    .small-width-bar {
        /* transition: opacity 0.15s ease-out; */
        transition: max-height 0.35s ease-in-out, opacity 0.2s ease-in-out, background-color 0s linear, visibility 0.1s ease-in;

        /* padding-left: 8px; */
        /* padding-right: 8px; */
        /* padding-top: 12px; */
        border-radius: 10px;

        /* background-color: #FFFA; */
    }

    .small-width-bar > *:not(:last-child) {
        /* margin-top: 5px; */
    }
    .small-width-bar > *:is(:last-child) {
        margin-bottom: 12px;
    }

    .small-width-bar > * {
        padding: 8px;
        background-color: #FFF;
        border-radius: 6px;
        text-align: left ;
        font-size: 16pt;
        padding-left: 24px;
        box-shadow: 0px 2px 5px #0003;
    }

}

.bar-title-font {
    letter-spacing: -0.7px;
}