
.icon {
    width: 70px;
}

.title {
    font-weight: 700;
    font-size: 18pt;
    line-height: 25pt;
    letter-spacing: -0.5px;
}

.subtitle {
    font-weight: 500;
    font-size: 12.5pt;
    letter-spacing: -0.5px;
    opacity: 0.7;
}

.fadeInBg {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%,
                                        #E7F8FC 20%, 
                                        #E7F8FC 100% );
                                        /* rgba(255,255,255,0) 100%); */
}
