
.grid {
    margin-left: 20px;
    margin-right: 20px;
}
.home-content-transition {
    /* margin-top: 35px; */
    transition: opacity 0.5s;
}

.home-content {
    margin-top: 0px;
}

@media (min-width: 768px) {
    .home-content {
        margin-top: 35px;
    }    
}

.home-min-h {
    /* min-height: 200px; */
    height: 900px;
}

@media (min-width: 768px) {
    .home-min-h {
        min-height: 580px;
        height: auto;
    }
}

@media (min-width: 1100px) {
    .home-min-h {
        min-height: 530px;
    }
}

@media (min-width: 768px) {
    .title {
        margin-top: 35px;
    }
}

.title {
    padding-bottom: 20px;
}

.title-lg {
    font-size: 62pt;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -3px;
}

.title-md {
    font-size: 24pt;
    font-weight: 500;
    line-height: 20pt;
    letter-spacing: -2px;
}

.subtitle {
    font-size: 40pt;
    font-weight: 600;
    letter-spacing: -3.5px;
    opacity: 1;
    color: rgb(47 64 97);
    margin-top: 30pt;
    /* margin-left: -3px; */
}

.description {
    font-size: 15pt;
    font-weight: 500;
    line-height: normal;
    margin-right: 30px;
    color: rgb(47 64 97);
    margin-top: 7.5px;
    margin-bottom: 25px;
    white-space: pre-line;
    width: 100%;
}

.left-contents {
    line-height: 60px;
    /* margin-left: 20px; */
    /* margin-top: 25px; */
    transition: 0.4s;
}

.badges-container {
    transition: 0.3s;
}

.badges-container :not(:last-child) {
    margin-right: 8px;
}

/* .right-contents {
} */

@media (min-width: 768px) { 
    .left-contents {
        text-align: left;
    }
}

@media (max-width: 768px) {
    .download-img {
        margin-left: auto;
        margin-right: auto;
    }
    .badges-container {
        justify-content: center;
    }
}

.download-img {
    height: 50px;
}

/* .fade-auto {
    animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
} */

.fade-enter{
    opacity: 0;
 }
 .fade-exit{
    opacity: 1;
 }
 .fade-enter-active{
    opacity: 1;
 }
 .fade-exit-active{
    opacity: 0;
 }
 .fade-enter-active,
 .fade-exit-active{
    transition: opacity 300ms;
 }

 .crossfadableProductDescription {
    transition: opacity 0.3s;
    overflow: hidden;
    height: 100% !important;
  }
  
  .before-enter {
    opacity: 0;
  }
  
  .entering {
    opacity: 1;
    transition: opacity 0.3s;
  }
  
  .before-leave {
    opacity: 1;
  }
  
  .leaving {
    opacity: 0;
    transition: opacity 0.3s;
  }


.featured-clickable-links {
    color: rgb(72, 63, 127);
    font-weight: 600;
    letter-spacing: -0.75px;
    font-size: 18pt;
}

.featured-clickable-links:hover {
    text-decoration: underline;
}

.featured-clickable-links span svg {
    font-size: 13pt;
    margin-bottom: 1px;
}

.bar-item {
    width: 3rem;
    height: 3rem;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

@media (min-width: 768px) {
    .bar-item {
        width: 2rem;
        height: 2rem;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
}

.crossfadableItems {
    transition: 
        transform 0.2s ease-out,
        opacity 0.2s ease-out;
}
  

.unselected-bar-item {
    opacity: 0.3;
}

.unselected-bar-item:hover {
    opacity: 0.8;
}

.selected-bar-item {
    transform: scale(1.5);
    margin-left: 8px;
    margin-right: 8px;
}

.item-bar {
    margin: 15px;
}