

.title {
    /* font-size: 18pt; */
    font-weight: 700;
    color: #30344F;
    letter-spacing: -0.3px;
}


.sectionCard {
    background-color: #fff6;
    border-radius: 20px;
    text-align: left;
    padding-top: 20px;
    padding-left: 30px;
    padding-bottom: 20px;
    padding-right: 30px;
    box-shadow: 0px 7px 12px #30344F30;
}

.sectionCard:not(:last-child) {
    margin-bottom: 1.6rem;
}

.sectionCard:is(:last-child) {
    margin-bottom: 5rem;
}