.mainHeader {
    position: relative;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
}

.mainHeader h1 {
    font-size: 38pt;
    font-weight: 600;
    font-style: italic;
}

.mainHeader p {
    font-size: 16pt;
    letter-spacing: -0.3px;
}

.dataContainer:not(:first-child) {
    margin-top: 55px;
}

.dataContainer {
    margin-top: 30px;
    margin-bottom: 30px;
}

@media (min-width: 768px) { 
    .dataContainer:not(:first-child) {
        margin-top: 0px;
    }

    .dataContainer {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.dataContainer:not(:last-child) {
    border-bottom: 1px solid rgb(100 100 100 / 0.2);
}

.description .thoughts p {
    font-size: 10pt;
    text-align: left;
    position: relative;
}

.yearTitle {
    position: sticky;
    position: -webkit-sticky;
    z-index: 10;
    top: 120px;

    font-size: 30pt;
}

.leftCellTitle {
    font-size: 16pt;
    opacity: 0.4;
    font-weight: 500;
    letter-spacing: -0.5px;
}

.leftCellSubtitle {
    font-size: 30pt;
    letter-spacing: -2.5px;
    font-weight: 700;
    padding-bottom: 5px;
}

.leftCell {
    text-align: left;
    line-height: 23pt;
}

.leftCellDescription {
    font-size: 12.5pt;
    line-height: 14pt;
    margin-top: 15px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-indent: 0;
    padding-bottom: 30px;
}

.leftCellCaption {
    font-size: 11pt;
    font-weight: 300;
    line-height: 18pt;
    opacity: 0.8;
}

.rightCell {
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 10px;
}

@media (min-width: 768px) { 
    .rightCell {
        padding-top: 8vh;
        padding-bottom: 8vh;
        padding-left: 35px;
        padding-right: 0px;
    }
}