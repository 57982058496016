
.imgContainer {
    display: flex;
    overflow: hidden;
    width: 100px;
    height: 75px;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
}


@media (min-width: 768px) {
    .imgContainer {
        width: 230px;
        height: 150px;
        justify-content: center;
        margin-left: 0;
    }
}


.imgContainer img {
    object-fit: cover;
    object-position: center;
    height: 100%;
}

.sideContent {
    font-family: "Figtree", -apple-system, BlinkMacSystemFont, sans-serif;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    letter-spacing: -0.5px;
    flex: 1;
}

.sideContent .sideTitle, .sideSubtitle, .date {
    font-family: "Figtree", -apple-system, BlinkMacSystemFont, sans-serif;
}

.sideContent h1 {
    font-size: 18pt;
    font-weight: 600;
    line-height: 1.1em;
    padding-bottom: 5px;
}

.sideContent h3 {
    font-size: 11pt;
    opacity: 0.7;
    letter-spacing: -0.25px;
}

.date {
    font-size: 16px;
    letter-spacing: -0.5px;
    font-weight: 600;
    color: rgb(72, 61, 139);
    margin-top: 8px;
}

@media (min-width: 768px) {
    .sideContent h1 {
        font-size: 23.5pt;
        padding-bottom: 2px;
    }

    .sideContent h3 {
        font-size: 12pt;
    }

    .date {
        font-size: 15pt;
    }
}

.eachCell {
    margin-bottom: 38px;
    flex-direction: row-reverse;
}

@media (min-width: 768px) {
    .eachCell {
        margin-bottom: 26px;
        flex-direction: row;
    }
}
