
@import url("./Sections.css");

/* .press-mentions {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%,
                                        rgba(227 247 254) 20%, 
                                        rgba(227 247 254) 70%, 
                                        rgba(255,255,255,0) 100%);
} */

.press-mentions {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, #E7F8FC 0%,
                                        #e7effc 10%, 
                                        
                                        #837bb7 100%);
                                        /* rgba(255,255,255,0) 0% ); */
                                        /* rgba(255,255,255,0) 100%); */
}