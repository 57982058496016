.mainHeader h1 {
    font-size: 38pt;
    font-weight: 600;
    font-style: italic;
    margin-top: 35px;
}

.mainHeader {
    position: relative;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
}
