.title {
    font-size: 30pt;
    font-weight: 600;
}

.titleEmoji {
    font-size: 90pt;
}

.subtitle {
    font-size: 20pt;
}

.homeButtonContainer {
    font-size: 19pt;
    font-weight: 500;
    letter-spacing: -0.5px;
    opacity: 0.7;
    margin: 20px;
}

.homeButton {
    padding: 10px 15px 10px 15px; 
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 3.5px 7px #0008;
    transition: linear 0.2s;
}

.homeButton:hover {
    background-color: #FFF5;
    box-shadow: 0px 2px 7px #0005;
}

.homeButton svg {
    font-size: 18pt;
}